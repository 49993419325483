import React from 'react';
import courierLogoEN from '../content/courier_logo.png';
import courierLogoFR from '../content/courier_logo_fr.png';
import loader from '../content/loader.gif';
import mcdlogo from '../content/mcdlogo.png';
import flourish from '../content/flourish.png';
import { APP_CONSTS, APP_ENDPOINTS, APP_STRINGS } from '../infrastructure/constants';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { appInsights as ai } from '../infrastructure/app-insights';
import { Settings } from '../components/Settings';
import { NotificationButton } from '../components/NotificationButton';
import alr from '../content/new-order.mp3';

const signalR = require("@microsoft/signalr");

let connection;

const alert = new Audio();

const STATUS_OFFLINE = 1;
const STATUS_RECONNECTING = 2;
const STATUS_ONLINE = 3;

export class McDLogger {

    _nsn = 0;

    constructor(nsn) {
        this._nsn = nsn;
    }

    /**
     * Send all log events to AI for monitoring
     * @param {signalR.LogLevel} logLevel 
     * @param {string} message 
     */
    log(logLevel, message) {
        try {
            ai.trackTrace({
                message: message,
                severityLevel: logLevel,
                properties: {
                    'nsn': this._nsn
                }
            });
        }
        catch(err) {
            console.error(err);
        }

    }
}


export const StoreDisplay = (props) => {

    const appInsights               = useAppInsightsContext();
    const trackConnection           = useTrackEvent(appInsights, APP_CONSTS.AI_STORE_CONNECTED);
    const trackDisconnect           = useTrackEvent(appInsights, APP_CONSTS.AI_STORE_DISCONNECT);
    const trackStartFailure         = useTrackEvent(appInsights, APP_CONSTS.AI_STORE_START_FAILED);
    const trackSubscribeFailure     = useTrackEvent(appInsights, APP_CONSTS.AI_STORE_SUBSCRIBE_FAILED);
    const trackOrderFetchFailure    = useTrackEvent(appInsights, APP_CONSTS.AI_STORE_ORDER_FETCH_FAILED);
    const trackMarkCompleteFailure  = useTrackEvent(appInsights, APP_CONSTS.AI_STORE_MARK_COMPLETE_FAILED);

    const [nationalNumber, setNationalNumber] = React.useState('');
    const [currentLanguage, setCurrentLanguage] = React.useState(APP_CONSTS.LANGUAGE_ENGLISH)
    const [locale, setLocale] = React.useState(APP_STRINGS.en);
    const [orders, setOrders] = React.useState([]);
    const [status, setStatus] = React.useState(STATUS_OFFLINE);
    const [showReload, setShowReload] = React.useState(false);
    const [activeOrder, setActiveOrder] = React.useState('');
    const [showSettings, setShowSettings] = React.useState(false);
    const [isAcceptingSMS, setIsAcceptingSMS] = React.useState(true);
    const [userAllowedSound, setUserAllowedSound] = React.useState(false);
    const orderListRef = React.useRef(orders);


    /**
     * Send a trace event with the given message back to AI
     * @param {string} msg 
     */
    const traceEvent = (msg) => {
        try{
            ai.trackTrace({
                message: msg,
                severityLevel: 0,
                properties: {
                    'nsn': nationalNumber
                }
            });
        }
        catch(err) {
            console.error(err);
        }
    };

    /**
     * Initial startup logic for connecting to SignalR
     */
    React.useEffect(() => {

        async function init() {

            const storeReq = await getStoreDetails();

            // We're stage and authed
            if (storeReq === true) {

                const nsn = localStorage.getItem('StoreID');
                setNationalNumber(nsn);

                // Build our connection
                connection = new signalR.HubConnectionBuilder()
                    .withUrl(APP_ENDPOINTS.RESTAURANT_HUB)
                    .configureLogging(new McDLogger(nsn))
                    .withAutomaticReconnect()
                    .build();

                // Wire up listener to order check-in event
                connection.on(APP_ENDPOINTS.SIGNALR_REFRESH_LIST, onRefreshOrderList);

                // Handle reconnecting event
                connection.onreconnecting(error => {
                    traceEvent('Client is reconnecting');
                    setStatus(STATUS_RECONNECTING);
                });

                // Handle the reconnected event
                connection.onreconnected((connectionId) => {
                    traceEvent('Client is reconnected');
                    setStatus(STATUS_ONLINE);
                    window.location.reload();
                });

                // Handle closing connection
                connection.onclose(error => {
                    console.assert(connection.state === signalR.HubConnectionState.Disconnected);
                    traceEvent('Client connection was closed');
                    trackDisconnect({ nsn: nationalNumber });
                    setStatus(STATUS_OFFLINE);
                    setShowReload(true);
                });

                // Start the connection and fetch orders
                startConnection(nsn);

            }
            else {

                const urlParams = new URLSearchParams(window.location.search);
                const qsId = urlParams.get('restaurantId');
                const qsKey = urlParams.get('key');

                if (!qsId || qsId.length === 0 || !qsKey || qsKey.length === 0) {
                    window.location.replace('/error');
                    return;
                }
                else {

                    await fetch('/authentication/login', {
                            method: 'POST',
                            body: JSON.stringify({ restaurantId: qsId, key: qsKey }),
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        })
                        .then(resp => window.location.replace(`/?restaurantId=${qsId}&key=${qsKey}`))
                        .catch(err => console.log(err));
                }
            }
        }

        init();

    }, []);

    /**
     * Take a ref on our order list to work around stale state in websocker callback
     */
    React.useEffect(() => {
        orderListRef.current = orders;
    }, [orders])
    
    /**
    * Fetch the restaurant ID from the server
    */
    const getStoreDetails = async () => {
        return await fetch('configuration/settings', {
                method: 'GET',
                credentials: 'same-origin'
            })
            .then(response => response.json())
            .then(data => {
                localStorage.setItem('StoreID', data.restaurantId);
                return true;
            })
            .catch(() => {
                localStorage.removeItem('StoreID');
                return false;
            });
    }

    /**
     * Start the SignalR connection 
     * @param {string} nsn The store's national number
     */
    const startConnection = (nsn) => {

        traceEvent('Starting Connection...');

        // Connect to RestaurantHub
        connection.start()
            .then(function () {

                setStatus(STATUS_ONLINE);

                traceEvent('Connection Started!  Attempting to subscribe to updates...');

                // Subscribe to changes
                connection
                    .invoke(APP_ENDPOINTS.SIGNALR_SUBSCRIBE, nsn)
                    .then(function () {
                        getOrders(true);
                        queryEnabledStatus();

                        traceEvent('Client connected and subscribed');
                        trackConnection({ nsn: nsn });
                    })
                    .catch(function (err) {
                        // Show the reload screen
                        setShowReload(true);

                        // Track that subscribing failed
                        const msg = err.toString();
                        traceEvent('Unable to subscribe client.');
                        trackSubscribeFailure({ errorMsg: msg, nsn: nsn });

                        // Return
                        return console.error(msg);
                    });
            })
            .catch(function (err) {
                // Show the reload screen
                setShowReload(true);

                // Track that starting failued
                const msg = err.toString();
                traceEvent('Connection unable to start');
                trackStartFailure({ errorMsg: msg, nsn: nsn });

                // Return
                return console.error(msg);
            });
    };

    /**
     * Send a completion event to the hub for the provided order number
     * @param {string} orderNumber The order number to mark completed
     */
    const completeOrder = (orderNumber) => {
        
        traceEvent(`Marking order ${ orderNumber } complete...`);

        setActiveOrder(orderNumber);

        connection
            .invoke(APP_ENDPOINTS.SIGNALR_MARKCOMPLETE, orderNumber)
            .then(function () {
                traceEvent(`Marking order ${orderNumber} completed successfully!`);
                getOrders(true);
                setActiveOrder('');
            })
            .catch(function (err) {
                // Track that marking complete failed
                const msg = err.toString();
                const orMsg = `ORD-${orderNumber}`;

                traceEvent(`Marking order ${orderNumber} failed!`);
                trackMarkCompleteFailure({ errorMsg: msg, orderNumber: orMsg, nsn: nationalNumber });

                // Return
                setActiveOrder('');
                return console.error(msg);
            });
    };

    /**
     * Get the store's current status
     */
     const queryEnabledStatus = () => {
        
        traceEvent(`Getting restaurant status...`);

        return connection
            .invoke(APP_ENDPOINTS.SIGNALR_GETSTATUS)
            .then(function (isEnabled) {
                setIsAcceptingSMS(isEnabled);
            })
            .catch(function (err) {
                // Track that getting the enabled status failed
                const msg = err.toString();
                traceEvent(`Failed to get restaurant status!`);

                // Return
                return console.error(msg);
            });
    };

    /**
     * Change the store's current status
     */
    const changeEnabledStatus = () => {
    
        traceEvent(`Changing restaurant status...`);

        return connection
            .invoke(APP_ENDPOINTS.SIGNALR_SETSTATUS, !isAcceptingSMS)
            .then(queryEnabledStatus())
            .catch(function (err) {
                // Track that changing the status failed
                const msg = err.toString();
                traceEvent(`Failed to change restaurant status!`);

                // Return
                return console.error(msg);
            });
    };

    /**
     * SignalR callback for being told to go fetch the order list
     * @param {*} data 
     */
    const onRefreshOrderList = (data) => {
        traceEvent('Refresh Order List message received');
        getOrders();
    };

    /**
     * Use SignalR to go fetch orders for the store
     */
    const getOrders = (shouldSupressAudio) => {

        traceEvent(`Getting orders for store...`);

        //invoke refresh order command on hub
        connection
            .invoke(APP_ENDPOINTS.SIGNALR_GETORDERS)
            .then(function (data) {
                traceEvent(`Get orders completed successfully`);

                const oRef = orderListRef.current;
                const ignore = data.length < oRef.length;

                if(shouldSupressAudio !== true && data.length > 0 && !ignore) {
                    alert.src = alr;
                    alert.play()
                        .catch((err) => {
                            traceEvent("Tried to play audio, but failed.  Likely due to not enabling sounds first");
                        })

                }

                setOrders(data);
            })
            .catch(function (err) {
                // Track that order fetch failed
                const msg = err.toString();
                traceEvent(`Get orders failed`);
                trackOrderFetchFailure({ errorMsg: msg, nsn: nationalNumber });

                // Return
                return console.error(msg);
            });
    }

    /**
     * Toggle language between French and English
     */
    const switchLanguage = () => {
        if (currentLanguage === APP_CONSTS.LANGUAGE_ENGLISH) {
            setCurrentLanguage(APP_CONSTS.LANGUAGE_FRENCH);
            setLocale(APP_STRINGS.fr);
            traceEvent("Language changed to French");
        }
        else {
            setCurrentLanguage(APP_CONSTS.LANGUAGE_ENGLISH);
            setLocale(APP_STRINGS.en);
            traceEvent("Language changed to English");
        }
    }

    /**
     * Reload the page to restart the process
     */
    const refreshPage = () => {
        traceEvent(`Page refreshed via tapping on overlay`);
        window.location.reload(false);
    }

    /**
     * Reload the page when the logo is pressed
     */
    const logoRefreshClick = () => {
        traceEvent(`Page refreshed via tapping on logo`);
        window.location.reload(false);
    }

    /**
     * Based on the current langauge, get the right logo
     */
    const getLogo = () => {
        return (currentLanguage === APP_CONSTS.LANGUAGE_ENGLISH)
            ? courierLogoEN
            : courierLogoFR;
    }

    /**
     * Based on the current language and status, get the right string
     */
    const getStatus = () => {
        switch (status) {
            case 1:
                return locale.OFFLINE;
            case 2:
                return locale.RECONNECTING;
            case 3:
                return locale.ONLINE;
            default:
                return locale.OFFLINE;
        }
    }

    /**
     * Logout the current session
     */
    const onLogout = async () => {

        await fetch('/authentication/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(resp => {
                caches
                    .open('auth')
                    .then(function (cache) {
                        cache.delete('/fake-endpoint');
                        window.location.replace('/')
                    });
            })
            .catch(err => console.log(err));

    }

    /**
     * Respond to a user interaction to allow sound to be played
     */
    const allowNotification = () => {
        alert.play();
        setUserAllowedSound(true);
    }

    return (
        nationalNumber && nationalNumber.length > 0 
        ? <div className="fullscreen">
            <div className="row">
                <div className="col">
                    <div className="header-blocks">
                        <div className="block center-hack">
                            <img
                                className="courier-logo"
                                src={getLogo()}
                                alt="Courier Logo"
                            />
                        </div>
                        <div className="block">
                            <div className="subblock order-count-block">
                                <h2>{orders.length}</h2>
                                <p>
                                    {locale.HEADER_PENDING_ORDERS_TOP}
                                    <br />
                                    {locale.HEADER_PENDING_ORDERS_BOTTOM}
                                </p>
                            </div>
                        </div>
                        <div className="block">
                            <div className="location-block">
                                <img src={mcdlogo} alt="McD Logo" />
                                <p>
                                    {locale.HEADER_LOCATION}
                                    <br />
                                    {nationalNumber}
                                </p>
                            </div>
                        </div>
                        <div className="block">
                            <div className="status-block">
                                <div className="store-status">
                                    {getStatus()}
                                </div>
                                <div>
                                    <button
                                        className="language-change-btn status-block store-status"
                                        onClick={() => setShowSettings(true) }>
                                        {locale.SETTINGS}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="clearHeader" />

            <div className="row">
                <div className="col">
                    {
                        orders && orders.length > 0 && isAcceptingSMS
                            ? (
                                <table className="table order-list-table">
                                    <thead>
                                        <tr>
                                            <th className="order-col">{locale.ORDER_LIST_COLUMN_NUMBER}</th>
                                            <th className="action-col">{locale.ORDER_LIST_COLUMN_ACTION}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            orders.map(itm =>
                                                <tr
                                                    key={itm.id}
                                                    className="order-table-row">
                                                    <td>
                                                        {itm.id}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        <button onClick={() => completeOrder(itm.id)}>                                                            
                                                            {
                                                                activeOrder === itm.id
                                                                    ? <img src={loader} />
                                                                    : locale.MARK_COMPLETE
                                                            }
                                                            <img
                                                                className="flourish"
                                                                src={flourish}
                                                                alt="flourish"
                                                            />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            )
                            : (
                                isAcceptingSMS
                                    ? <div className="no-orders">{ locale.NO_ORDERS_MESSAGE }</div>
                                    : <div className="no-orders">{ locale.STORE_NOT_ENABLED }</div>
                            )
                    }
                </div>
            </div>
            <div className="footer-logo">
                <img onClick={ logoRefreshClick } src={getLogo()} alt="Footer Logo" />
                <p className="version-text">Ver: 20210324.4</p>
                {
                    userAllowedSound
                        ? null
                        : <NotificationButton 
                            locale={ locale }
                            grantAccess={ allowNotification } />
                }            
            </div>

            {
                showReload
                    ? <div className="reconnect-btn" onClick={refreshPage}>
                        <h1>{locale.NO_CONNECTION_TOP}</h1>
                        <h2>{locale.NO_CONNECTION_BOTTOM}</h2>
                    </div>
                    : null
            }

            {
                showSettings
                    ? <Settings
                        closeSettings={ () => setShowSettings(false) }
                        switchLanguage={ switchLanguage }
                        isEnabled={ isAcceptingSMS }
                        setEnabled={ changeEnabledStatus }
                        getStatus={ queryEnabledStatus }
                        setStatus={ changeEnabledStatus }
                        locale={ locale }
                        reloadPage={ logoRefreshClick }
                    />
                    : null
            }
            

        </div>
        : <div className="fullscreen" />
    );
}
