import React from 'react';
import { APP_CONSTS, APP_STRINGS } from '../infrastructure/constants';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

export const ErrorScreen = () => {

    const appInsights = useAppInsightsContext();
    const trackNoPageEvent = useTrackEvent(appInsights, APP_CONSTS.AI_NOT_CONFIG);
    const [english] = React.useState(APP_STRINGS.en);
    const [french] = React.useState(APP_STRINGS.fr);

    React.useEffect(() => {
        trackNoPageEvent();
    }, []);

    return (
        <div className="fullscreen">            
            <div className="row no-store-message">
                <div className="col-auto">
                    <p>{ english.ERROR_GENERIC }</p>
                    <p>{ french.ERROR_GENERIC }</p>
                </div>
            </div>
        </div>
    );
}
