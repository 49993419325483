import React from 'react';
import bell from '../content/bell.png';

export const NotificationButton = (props) => {

    return (
        <button 
            className="notification-button attention-animation" 
            onClick={ props.grantAccess }
        >
            <img src={ bell } alt="Notification Button"/>
            <p>{ props.locale.ALLOW_NOTIFICATIONS }</p>
        </button>
    )

}