import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from './infrastructure/app-insights';
import { StoreDisplay } from './screens/StoreDisplay';
import { ErrorScreen } from './screens/Error';

import './custom.css'

export default class App extends Component {

    static displayName = App.name;
    
    constructor() {
        super();
        this.state = { hasCookie : true, hasQs: true };
    }

    /**
     * Fetch the restaurant ID from the server
     */
    async getStoreDetails() {
        return await fetch('configuration/settings', {
            method: 'GET',
            credentials: 'same-origin'
        })
        .then(response => response.json())
        .then(data => {             
            localStorage.setItem('StoreID', data.restaurantId);
            return true;
        })
        .catch(() => { 
            localStorage.removeItem('StoreID');
            return false; 
        });
    }

    async componentDidMount() {
        const cookieResult = await this.getStoreDetails();             
        this.setState({ hasCookie : cookieResult});
        

        const urlParams = new URLSearchParams(window.location.search);
        const qsId = urlParams.get('restaurantId');
        const qsResult = (qsId && qsId.length > 0) ? true : false;
        this.setState({ hasQs: qsResult });
    }

    render() {
        return (
            <AppInsightsContext.Provider value={reactPlugin}>
                {
                    (this.state.hasCookie || this.state.hasQs)
                        ? <Layout>
                            <Switch>
                                <Route exact path='/' component={StoreDisplay} />
                                <Route exact path="/error" component={ErrorScreen}/>
                                <Redirect to="/" />
                            </Switch>
                        </Layout>
                        : <ErrorScreen />
                }
            </AppInsightsContext.Provider>
        );
    }
}
