export const APP_STRINGS = {
    en: {
        HEADER_PENDING_ORDERS_TOP: 'Pending',
        HEADER_PENDING_ORDERS_BOTTOM: 'Orders',
        HEADER_LOCATION: 'Location',
        HEADER_STATUS_TOP: 'Current',
        HEADER_STATUS_BOTTOM: 'Status',
        NO_ORDERS_MESSAGE: 'No Orders. You\'re crushing it.',
        CHANGE_LANGUAGE: 'Français',
        ONLINE: 'Online',
        OFFLINE: 'Offline',
        RECONNECTING: 'Reconnecting',
        ORDER_LIST_COLUMN_NUMBER: 'ORDER NUMBER',
        ORDER_LIST_COLUMN_ACTION: 'ACTIONS',
        MARK_COMPLETE: 'Mark Complete',
        ERROR_NO_STORE: 'This device is not set up properly. Please contact support for further assistance.',
        NO_CONNECTION_TOP: 'Check your Wi-Fry',
        NO_CONNECTION_BOTTOM: 'Tap to Reload',
        ERROR_GENERIC: "An Error Has Occurred. Please contact support.",
        LOGO: 'courier_logo.png',
        STORE_NOT_ENABLED: 'Your store is not accepting courier check-ins.  To change this, tap on the "Settings" button above.',
        SETTINGS: 'Settings',
        SETTINGS_CHANGE_LANGUAGE: 'Change Language',
        SETTINGS_CHANGE_LANGUAGE_DESC: 'Tap the button below to change the app language.',
        SETTINGS_STATUS_CHANGE: 'Change Status',
        SETTINGS_STATUS_ON_PT1: 'Your store is currently',
        SETTINGS_STATUS_ON_PT2: 'ON',
        SETTINGS_STATUS_ON_PT3: 'and accepting courier check-ins.',
        SETTINGS_STATUS_OFF_PT1: 'Your store is currently',
        SETTINGS_STATUS_OFF_PT2: 'OFF',
        SETTINGS_STATUS_OFF_PT3: 'and not accepting courier check-ins.',
        SETTINGS_TROUBLESHOOTING: 'Troubleshooting',
        SETTINGS_TROUBLESHOOTING_DESC: 'Tap below to reload your session.',
        SETTINGS_TROUBLESHOOTING_RELOAD: 'Reload',
        ALLOW_NOTIFICATIONS: 'Allow Notifications'
    },
    fr: {
        HEADER_PENDING_ORDERS_TOP: 'Commande en cours',
        HEADER_PENDING_ORDERS_BOTTOM: 'Commandes',
        HEADER_LOCATION: 'Emplacement',
        HEADER_STATUS_TOP: 'Actuelle',
        HEADER_STATUS_BOTTOM: 'l’état',
        NO_ORDERS_MESSAGE: 'Pas de commandes. Bon travail.',
        CHANGE_LANGUAGE: 'English',
        ONLINE: 'En ligne',
        OFFLINE: 'Pas en ligne',
        RECONNECTING: 'Reconnecting',
        ORDER_LIST_COLUMN_NUMBER: 'NUMÉRO DE COMMANDE',
        ORDER_LIST_COLUMN_ACTION: 'ACTIONS',
        MARK_COMPLETE: 'Terminé',
        ERROR_NO_STORE: "Cet appareil n'est pas configuré correctement. Veuillez contacter le support pour obtenir de l'aide.",
        NO_CONNECTION_TOP: 'Oh, frite! Problème de connexion',
        NO_CONNECTION_BOTTOM: 'Appuyez pour recharger',
        ERROR_GENERIC: "Une erreur est survenue. Veuillez contacter l'assistance.",
        LOGO: 'courier_logo_fr.png',
        STORE_NOT_ENABLED: 'Votre restaurant n’accepte pas les enregistrements de livreurs. Pour changer cet état, appuyer sur la touche « Réglages » ci-dessus.',
        SETTINGS: 'Réglages',
        SETTINGS_CHANGE_LANGUAGE: 'Changer la langue',
        SETTINGS_CHANGE_LANGUAGE_DESC: 'Appuyer sur la touche ci-dessous pour changer la langue de l’appli.',
        SETTINGS_STATUS_CHANGE: 'Changer l’état',
        SETTINGS_STATUS_ON_PT1: 'Votre restaurant est actuellement',
        SETTINGS_STATUS_ON_PT2: 'ACTIF',
        SETTINGS_STATUS_ON_PT3: 'et accepte les enregistrements de livreurs.',
        SETTINGS_STATUS_OFF_PT1: 'Votre restaurant est actuellement',
        SETTINGS_STATUS_OFF_PT2: 'INACTIF',
        SETTINGS_STATUS_OFF_PT3: 'et n’accepte pas les enregistrements de livreurs.',
        SETTINGS_TROUBLESHOOTING: 'Dépannage',
        SETTINGS_TROUBLESHOOTING_DESC: 'Appuyer ci-dessous pour relancer votre session.',
        SETTINGS_TROUBLESHOOTING_RELOAD: 'Relancer',
        ALLOW_NOTIFICATIONS: 'Permettre les notifications'
    }
};

export const APP_CONSTS = {
    LANGUAGE_ENGLISH: 'en',
    LANGUAGE_FRENCH: 'fr',
    AI_STORE_CONNECTED: 'StoreConnected',
    AI_STORE_DISCONNECT: 'StoreDisconnected',
    AI_STORE_START_FAILED: 'StoreStartFailed',
    AI_STORE_ORDER_FETCH_FAILED: 'StoreOrderFetchFailed',
    AI_STORE_MARK_COMPLETE_FAILED: 'StoreMarkCompleteFailed',
    AI_STORE_SUBSCRIBE_FAILED: 'StoreSubscribeFailed',
    AI_NOT_CONFIG: 'NotConfigured',
    AI_PROVISION_STORE: 'ProvisionStore'
}

export const APP_ENDPOINTS = {
    RESTAURANT_HUB: 'hubs/restaurant',
    SIGNALR_REFRESH_LIST: 'RefreshList',
    SIGNALR_SUBSCRIBE: 'Subscribe',
    SIGNALR_UNSUBSCRIBE: 'Unsubscribe',
    SIGNALR_MARKCOMPLETE: 'MarkComplete',
    SIGNALR_GETORDERS: 'GetOrders',
    SIGNALR_GETSTATUS: 'GetStatus',
    SIGNALR_SETSTATUS: 'SetStatus'
}